@import "./_colors.module";
@import "./_buttons.module";


.container {
  // max-width: 1440px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
}

.headerButtonContainer {
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.innerButtonContainer {
  height: 100%;
  // width: 1000px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  

  .baseHeaderButton {
    color: $darkText;
    border-radius: 20px;
    border-color: $lightGray;
    
    &:hover {
      color: $darkText;
      border-radius: 20px;
      border-color: $lightGray;
      background-color: transparent;
    }
  }
}

