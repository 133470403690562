@import './_buttons.module';
@import './_colors.module';

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkMark{
  height: 90;
  width: 90;
}

.thankYou {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0px;
}
.exitButton {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  width: 100%;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #787878;
}

.message {
  width: 360px;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 27px;

  text-align: center;

  color: #000000;

  .text {
    text-align: center;
    margin: 0;
    i {
      color: $hyperLink;
    }
  }
}

.continueButton {
  @extend .buttonGradientOrange;
  width: 170px !important;
  color: white !important;
  border-color: transparent !important;  
  border-radius: 20px !important;
}

.outlinedButton {
  color: $darkText !important;  
  border-radius: 20px !important;  
  border-color: $lightGray !important; 
  width: 100px !important;
  
  &:hover {
    color: $darkText !important;  
    border-radius: 20px !important;  
    border-color: $lightGray !important;  
    background-color: transparent !important;  
  }
}


.deleteAssetButtonDisabled { 
  color: #4E4E4E !important;
  border-radius: 20px  !important;
  border-color: $lighterGray  !important;
  background-color: $disabledAssetButton !important;
  width: 100px !important;
}

.deleteAssetButton {
  @extend .buttonGradientOrange;
  width: 100px !important;
  color: white !important;
  border-color: transparent !important;  
  border-radius: 20px !important;
}

.deleteAssetButton:focus {
  border-color: transparent !important;  
}
.deleteAssetButton:hover {
  border-color: transparent !important;  
}