@import "./_buttons.module";
@import "./colors.module";

.container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  width: 100%;
  // align-items: center;
}

.content {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  @media only screen and (min-width: 1465px) {
    min-height: 50vh;
  }
}

.inputStyle {
  background-color: $white;
  width: 80%;
  margin: 7px;
  font-size: 0.8em;
}

.requirementsContainer {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: auto;
}

.requirementsText{ 
  color: #FF8E0A;
  text-decoration: none;
}

.spaceContent {
  // margin: 25px 0;
  margin-bottom: 1%;
}
.attention {
  width: 32px;
  height: 32px;
  margin-right: -5px;
}


.text {
  width: 50%;
  height: 50%;
  color: #787878;
  font-size: 14px;
  margin-left: 15px;
  font: source-sans-pro;
} 

.form { 
  display: flex;
  flex-direction: column;
  // align-items: center;\
}


.inputSpacing{
  padding: 12px 0;
}

.nextButton { 
  @extend .nextButton
}

.buttonGroup {
  width: 40%;
  justify-self: end;
  margin-top: auto;
  // margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customCarousel {
  margin-top: 4%;
  margin-bottom: 4%;
}

.buttonUploadModal {
  @extend .buttonGradientOrange;
  border-radius: 20px;
  width: 170px;
  font-weight: 600;
  height: 40px;
  text-transform: none !important;
}