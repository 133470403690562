@import './_colors.module.scss';
@import './_buttons.module.scss';

.navbar {
  background: transparent !important;
  box-shadow: none !important;
  color: $mainColor !important;

  .links {
    background: black !important;
    color: white !important;
    text-decoration: none !important;

    &:hover {
      background: black !important;
      color: white !important;
    }
  }
}

.navLinks {
  background: transparent !important;
  box-shadow: none !important;
  padding-top: 0px !important;

  a {
    text-decoration: none;
    color: $mainColor;
    .itemIcon {
      padding: 0;
      margin: 0;
      min-width: 0;
      width: 28px;
      padding-left: 10px;
    }
  
    &:hover {
      background-color: #f7f7f7;
      color: $mediumDarkGray;
      img {
        color: $mediumDarkGray;
        filter: invert(24%) sepia(0%) saturate(1069%) hue-rotate(153deg) brightness(103%) contrast(109%);
      }
    }
    &:active {
      @extend .buttonGradientOrange;
    }
    .active {
      background-color: black !important;
      background: $mainColor !important;
    }
  }
  
  .active {
    @extend .buttonGradientOrange;
    img {
      filter: invert(100%) sepia(4%) saturate(2781%) hue-rotate(66deg) brightness(112%) contrast(100%);
    }

    &:hover {
      color: $backgroundColor;
      img {
        color: $backgroundColor;
        filter: invert(100%) sepia(4%) saturate(2781%) hue-rotate(66deg) brightness(112%) contrast(100%);
      }
    }
  }

  .links {
    background-color: inherit !important;
    background: inherit !important;
  }
}

.menuButton {
  color: $mainColor !important;
}
.drawer {
  // border-right: 5px solid black !important;
  width: 300px;
  flex-shrink: 0;

  .drawerHeader {
    display: flex;
    align-items: center;
    // padding: 10px 8px;
    justify-content: center;
    background-color: #f7f7f7;
    height: 110px;
    width: 256px;

    img {
      padding: 0px 50px;
      padding-top: 10px;
      height: 35.5px;
      width: 197px;
    }
  }

  .MuiList-root {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }

  .MuiDrawer-paper {
    width: 300px;
    box-sizing: 'border-box';
  }

  .signOutContainer {
    color: grey;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px;
  }

  .signOutButtonStyle {
    color: grey !important;
    width: 100%;
    text-transform: none !important;
    img {
      color: #808080 !important;
      filter: invert(46%) sepia(42%) saturate(0%) hue-rotate(196deg) brightness(100%) contrast(93%) !important;
    }
    &:hover {
      color: black !important;
      img{
        filter: invert(0%) sepia(7%) saturate(28%) hue-rotate(300deg) brightness(105%) contrast(106%) !important;
      }
    }
  }
}
