@import "./_buttons.module";
@import "./colors.module";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
//   align-items: center;
}

.content {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  @media only screen and (min-width: 1465px) {
    min-height: 50vh;
  }
}
.inputStyle {
  background-color: $white;
  width: 100%;
  margin-left: 7px;
  font-size: 0.8em;
}

// .nextButton {
//   @extend .buttonGradientOrange;
//   width: 80%;
//   text-align: center;
//   border-radius: 4px;
//   font-size: 0.9em;
//   text-transform: capitalize;
//   font-weight: 800;
// }

.form {
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 80%;
}

.inputSpacing {
  padding: 12px 0;
}

.firstInputSpacing {
  @extend .inputSpacing;
  padding-top: 0;
}

.nextButton {
  @extend .nextButton;
}

.buttonGroup {
  width: 40%;
  justify-self: end;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    margin-right: 20px;
  }
}

.inputLabel {
  color: $mainColor;
  font-weight: 400;
  font-size: 14px !important;
  color: rgb(0, 0, 0) !important;
}

.toolbarCustom {
  width: 98.5%;
  margin-left: 7px;
}

.tagsContainer {
  margin-left: 7px;
  width: 80%;
}
