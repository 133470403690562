@import "./_buttons.module";
@import "./_colors.module";

.headerRowItems {
  background-color: $lightBeigeGray;
}

.bodyRow {
  border-radius: 5px;
}

.nextPrevPageButtons {
  border-color: $uiLightOrange !important;
  // border-radius: 50%  !important;
  // background: white;
  font-size: 0.7rem;
  color: $uiLightOrange !important;
  width: 30px  !important;
  height: 30px  !important;
  margin: 2px !important;
}

.nextPrevPageButtons:hover {
  border-color: $uiLightOrange !important;
}

.nextPrevPageButtons:disabled {
  border-color: $mediumGray !important;
  color: $mediumGray !important;
}

.pageButtons {
  margin: 2px !important;
  // border-radius: 50%  !important;
  background: white;
  font-size: 0.7rem;
  background: #e3e3e3;
  color: $uiLightOrange !important;
  border: 1px solid $uiLightOrange !important;
  width: 30px  !important;
  height: 30px  !important;
}

.pageButtons:disabled {
  border-color: $mediumGray !important;
  color: $mediumGray !important;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 1500px;

  // .DataTable-tableWrapper-3 {
  //   display: table !important;
  // }

  // .tableHeader  {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-between;
  // }

  .buttonUploadModal {
    @extend .buttonGradientOrange;
    border-radius: 20px;
    width: 170px;
    font-weight: 600;
    height: 40px;
    text-transform: none !important;
  }

  .inputStyle {
    background-color: $white !important;
    width: 80% !important;
    margin: 7px;
    font-size: 0.8em;
  }

  .creationButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    div {
      padding: 10px;
    }
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    padding: 5px 0px;
  }
}

.inputItems {
  width: 100%;
}

.formContent {
  width: 500px;

  .inputStyle {
    width: 100% !important;
    font-size: 0.8em;
  }
}

.createAssetButton {
  @extend .nextButton;
  text-align: center;
  border-radius: 20px;
  font-size: 0.9em;
  text-transform: capitalize;
  font-weight: 800;
  height: 40px;
  width: 170px;
}

.assetLink {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff8e0a;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #ffbd00;
  }
}

.termsDisclaimer {
  color: #787878;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.inputLabel {
  color: $mainColor;
  font-weight: 400;
  font-size: 14px;
}
