@import './_colors.module';
@import './_buttons.module';

.container {
  height: 100vh;
  max-width: 2500px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: $backgroundColorGray;

  .headerItemsLoading {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 40px;
    }
  }

  .loadingCircularContainer {
    width: 100%;
    height: 20px;
    margin-top: 13px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 700px;

    .headerItems {
      padding: 10px;
      margin-bottom: 30px; 
      
      img {
        width: 200px;
        margin: 40px;
      }
    }

    .loadingContainer {
      width: 100%;
      height: 20px;
      margin-top: 13px;
      margin-bottom: 18px;
    }

    .inputItems {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 150px;
      margin-top: -35px;
      justify-content: center;

      .inputLabel {
        margin-left: 32px;
        font-size: 0.9em;
        color: $lightBeigeGray;
      }

      .inputStyle {
        background-color: $white;
        width: 80%;
        margin: 7px;
        margin-left: 30px;
        font-size: 0.8em;
        margin-bottom: 13px;
      }

      .passwordInput {
        margin-bottom: 13px;
      }
    }

    .passwordToggleButton {
      transform: scale(0.7);
    }

    .forgotPasswordContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 30px;
      width: 87%;
      margin-top: -31px;
      margin-bottom: 8px;
      margin-left: 14px;

      .forgotPasswordButton {
        background-color: none;
        text-transform: capitalize;
        text-decoration: underline;
        color: $uiLightOrange;
        font-size: 0.8em;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 120px;
      margin-top: -20px;
    }

    .logInButton {
      @extend .buttonLogin !optional;
      border-radius: 25px;
    }

    .createNewUserButton {
      @extend .buttonBeigeCreateAccount;
      background-color: $backgroundColorGray;
      border-radius: 25px;
    }

  
  }
}

.linearProgress {
  padding: 5px;
  border-radius: 15px;
}

.socialLoginContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  color: $lightBeigeGray;
  margin-top: 2px;

  .dividerLoginContainer {
    height: 58px;
    width: 80%;
    max-width: 250px;
    margin: auto;
  }

  .googleFacebookContainer {
    width: 95%;
    height: 26px;
    justify-content: space-around;
    align-items: center;
  }

  .googleSocialButton {
    @extend .buttonBeigeCreateAccount;
    width: 40%;
    padding: 5px;
    margin-right: 10px;
    border-radius: 25px;
    max-width: 120px;
  }

  .facebookSocialButton {
    @extend .buttonBeigeCreateAccount;
    width: 40%;
    padding: 5px;
    border-radius: 25px;
    max-width: 120px;
  }

  .appleSocialButtons {
    @extend .buttonBeigeCreateAccount;
    margin: auto;
    border-radius: 25px;
    max-width: 250px;
  }
}