@import "./_buttons.module";
@import "./colors.module";


.container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  width: 100%;
  // align-items: center;
}

.content {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
  @media only screen and (min-width: 1465px) {
    min-height: 50vh;
  }
}

.text {
  color: #787878;
  font-size: 14px;
  font: source-sans-pro;
  margin-top: 7px;

  a {
    color: $hyperLink;
    text-decoration: underline;
  }
}

.buttonGroup {
  width: 40%;
  justify-self: end;
  margin-top: auto;
  // margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}