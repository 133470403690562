
.imageUploadIcon {
  filter: invert(99%) sepia(0%) saturate(471%) hue-rotate(238deg) brightness(117%) contrast(78%);
  position: absolute;
}

.container { 
  display: flex;
  height: 125px;
  width: 160px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  // .staticGif {
  //   height: 125px; 
  //   width: 160px;
  //   // opacity:0;
  //   border-radius: 8px;
  // }

  // .staticGif:hover {
  //   opacity:1;
  // }

  .removeImageContainer {
    display: none;
    width: 100%;
    .removeImage{
      display: none;
    }
  }
  
  &:hover { 
    .removeImageContainer {
      width: 100%;
      display: flex;
      align-self: flex-start;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 5px 5px;
      // position: absolute;

      .removeImageCircleContainer {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        // margin-right: -5px;
        display: flex;
        background-color: #e3e3e3;
        align-items: center;
        justify-content: center;
        // position: absolute;
      }

      .removeImage {
        display: inline-block;
        position: absolute;
        align-self: center;
        height: 8px;
        width: 8px;
      }
    }
  }
}

.mainTextContainer {
  // background: linear-gradient(90deg, #F26722 0%, #FAAD30 100%);
  // width: 100%;
  // border-radius: 0px 8px;
  // width: 60px;
  // height: 20px;
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 160px;

  .mainTextContent {
    width: 160px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start !important;
    align-self: flex-start !important;

    .mainTextBox{
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      background: linear-gradient(90deg, #F26722 0%, #FAAD30 100%);
      border-radius: 0px 8px 0px 3px;
      width: 60px;
      height: 20px;
      font-size: 12px;
    }
  }
}