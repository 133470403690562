/* Upload Component SCSS */

@import "./_buttons.module";
@import "./colors.module";

.container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  width: 100%;
  // max-width: 1440px;
  // align-items: center;
}

.content {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  @media only screen and (min-width: 1465px) {
    min-height: 50vh;
  }
}

h2 {
  font-size: 1.1em;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;
}

a {
  color: #FF8E0A;
  text-decoration: none;
} 

.itemCreated {
  color: #FF8E0A;
}

p {
font-size: 14px;
}

.requirementsContainer {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: auto;
}

.attention {
  width: 32px;
  height: 32px;
  margin-right: -5px;
}

.text {
  color: #787878;
  font-size: 14px;
  font: source-sans-pro;
  margin-top: 7px;
  a {
    text-decoration: underline;
  }
} 

.uploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  // margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
}

.hiddenFileInput {
  display: none;
}

.uploadFileButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 170px;
  height: 40px;
  border: 1px solid #ABABAB;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
}

.fileNameContainer
{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 100%;
  width: 400px;
}

.fileIcon
{
  width: 35px;
  height: 46px;
  margin-right: 5px;
}

.fileName
{
  font-size: 12px;
  margin-left: 10px;
}

.fileClose
{
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top:5px;
  cursor: pointer;
}


.assetFileContainer {
  display: flex;
  height: 60px;
  width: auto;
  margin-top: 20px;
}

.nextButtonContainer {
  // align-self: baseline;
  // bottom: 0;
  width: 40%;
  justify-self: end;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nextButton {
  @extend .buttonGradientOrange;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 170px;
  height: 40px;
  // background: #FFBD00;
  border-radius: 20px;
  border: none;
  color: white;
  cursor: pointer;
}

// .nextButton:focus {
//   background: #FFBD00;  
// }

.nextButton:disabled {
  background: #C4C4C4;
  color: #787878;
  cursor: not-allowed;
}

.linearProgress {
  padding: 5px;
  border-radius: 15px;
}

.loadingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  // width: 300px;
}
