@import './_colors.module.scss';
// TODO: need to update based on the diffection colors

.buttonShared {
  color: $white;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  transition: background-color 0.2s linear;
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  font-family: 'Source Sans Pro', sans-serif;
}

.buttonDisabled {
  @extend .buttonShared;

  background-color: #2f2f2f;
  cursor: not-allowed;
}

.buttonGhost {
  @extend .buttonShared;

  &:hover {
    background-color: $white;
  }

  &:active {
    background-color: scale-color($white, $lightness: -5%);
  }
}

.buttonRegularOrange {
  @extend .buttonShared;

  background-color: $uiOrange;

  &:hover {
    background-color: scale-color($uiOrange, $lightness: +15%);
  }

  &:focus {
    background-color: scale-color($uiOrange, $lightness: +15%);
  }
}

.buttonRegularYellow {
  @extend .buttonShared;

  background-color: $uiYellow;

  &:hover {
    background-color: scale-color($uiYellow, $lightness: +15%);
  }

  &:focus {
    background-color: scale-color($uiYellow, $lightness: +15%);
  }
}

.buttonGradientOrange {
  @extend .buttonShared;

  background-image: linear-gradient(to right, $uiOrange, $uiYellow);

  &:hover {
    background-image: none;
    background-image: linear-gradient(to right, #ff5805, #ff9e00);
  }

  &:focus {
    background-image: none;
    background-image: linear-gradient(to right, #ff5805, #ff9e00);
  }
}

.buttonReglarGrey {
  @extend .buttonShared;

  background-color: $mediumGray;

  &:hover {
    background-color: $mediumLightGray;
  }

  &:focus {
    background-color: $darkGray;
  }
}

.buttonReglarDarkGrey {
  @extend .buttonShared;

  background-color: $mediumDarkGray;
  color: #ababab;
  font-family: 'Roboto';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background-color: $mediumLightGray;
  }

  &:focus {
    background-color: $darkGray;
  }
}

.buttonGhostGrey {
  @extend .buttonGhost;
  background: $white;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  color: #a1a1a1;

  &:hover {
    color: $mediumLightGray;
  }

  &:active {
    color: $mediumLightGray;
  }
}

.buttonGhostBlue {
  @extend .buttonGhost;
  background-color: $uiBlue;

  border-width: 2px;
  border-color: $uiBlue;
  border-style: solid;

  &:hover {
    color: $uiBlue;
  }

  &:active {
    color: $uiBlue;
  }
}

.buttonGhostDarkBlue {
  @extend .buttonGhost;
  background-color: $uiDarkBlue;

  border-width: 2px;
  border-color: $uiDarkBlue;
  border-style: solid;

  &:hover {
    color: $uiBlue;
  }

  &:active {
    color: $uiBlue;
  }
}

.buttonGhostOrange {
  @extend .buttonGhost;
  background-color: $white;
  color: $uiOrange;
  border-width: 2px;
  border-color: $uiOrange;
  border-style: solid;

  &:hover {
    background-color: $uiOrange;
    color: $white;
  }

  &:active {
    background-color: $uiOrange;
    color: $white;
  }
}

.buttonGhostGradientOrange {
  @extend .buttonGhost;
  background-color: $white;
  color: $uiOrange;
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(to right, $uiOrange, $uiYellow);
  border-image-slice: 1;

  &:hover {
    color: $uiOrange;
  }

  &:active {
    color: $uiOrange;
  }
}

.buttonGhostGreen {
  @extend .buttonGhost;
  background-color: $uiGreen;

  border-width: 2px;
  border-color: $uiGreen;
  border-style: solid;

  &:hover {
    color: $uiGreenAlt;
    border-color: $uiGreenAlt;
  }

  &:active {
    color: $uiGreenAlt;
    border-color: $uiGreenAlt;
  }
}

.buttonGhostDisabled {
  @extend .buttonGhost;
  background-color: #d0dadb;
  color: white;
  cursor: not-allowed;
  border: 1px solid white;
}

.clientLoginText {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Ubuntu;
  color: #ababab;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  padding: 25px 0 20px 0;
  max-width: 250px;

  &.error {
    color: #ce3530;
  }

  &.link {
    cursor: pointer;
    text-decoration: underline;

    .arrow {
      margin-right: 6px;
    }

    &:hover {
      color: #fff;
    }
  }

  &.signOut {
    padding: 10px 0;
  }

  @media screen and (max-width: 768px) {
    color: #4b4b4b;
    max-width: 284px;
  }
}
.signOutText {
  color: $uiOrange;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}
.returnToSignIn {
  @extend .clientLoginText;
  padding: 20px 0px;

  &:hover {
    color: #fff;
  }
}

.buttonFilled {
  box-sizing: border-box;
  border-radius: 20px !important;
  border: 1px solid $mainColor !important;
  background-color: $mainColor !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}

.buttonOutlined {
  box-sizing: border-box;
  border-radius: 20px !important;
  border: 1px solid $mainColor !important;
  background-color: $backgroundColor !important;

  &:hover {
    background-color: rgba(0, 0, 0) !important;
  }
}

.buttonLogin {
  @extend .buttonGradientOrange;
  width: 80%;
  text-align: center;
  border-radius: 4px;
  font-size: 0.9em;
  text-transform: capitalize;
  font-weight: 800;
}

.buttonBeigeCreateAccount {
  background-color: $backgroundColorGray;
  color: $mediumGray;
  width: 80%;
  text-align: center;
  border-radius: 4px;
  border: 0.5px solid $mediumGray;
  font-size: 0.9em;
  text-transform: capitalize;
  font-weight: 800;
  margin-top: -23px;
}


.nextButton {
  @extend .buttonGradientOrange;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 170px !important;
  padding: 0px !important;
  height: 40px !important;
  // background: #FFBD00 !important;
  border-radius: 20px !important;
  border: none;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

// .nextButton:focus {
//   background: #FFBD00 !important;  
// }

.nextButton:disabled {
  background: $disabledAssetButton !important;
  color: #787878 !important;
  cursor: not-allowed !important;
}

.submitAssetButtonDisabled { 
  // @extend .buttonGhost;

  color: #4E4E4E !important;
  border-radius: 20px  !important;
  border-color: $lighterGray  !important;
  background-color: $disabledAssetButton !important;
  width: 170px !important;
  // &:hover {
  //   color: $darkText  !important;
  //   border-radius: 20px  !important;
  //   border-color: $lightGray  !important;
  //   background-color: transparent  !important;
  // }

}

.submitAssetButton {
  @extend .buttonGradientOrange;
  background-image: linear-gradient(to right, $uiBlue, $uiGreen);
  width: 170px !important;
  color: white !important;
  border-color: transparent !important;  
  border-radius: 20px !important;
}

.submitAssetButton:focus {
  border-color: transparent !important;  
}
.submitAssetButton:hover {
  border-color: transparent !important;  
  background-image: linear-gradient(to right, $uiGreen, $uiBlue);
}

.tooltip {
  color: #ABABAB;
  cursor: pointer;
  &:hover {
    color: green;
    fill: $hoverYellow;
  }
}

