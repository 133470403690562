$mainColor: black;
$backgroundColor: white;
$backgroundColorGray: #E3E3E3;
$lightBeigeGray: #ABABAB;
$disabledAssetButton: #d7d7d7;
$darkText:#4E4E4E;
$hoverYellow: #FFBD00;
$hyperLink: #FF8E0A;

// Primary
$lighterGray: #e1e1e1;
$lightGray: #676767;
$mediumLightGray: #686868;
$mediumGray: #444444;
$mediumDarkGray: #3a3a3a;
$darkGray: #0f0f0f;
$black: #000;
$lightBlack: #1e1e1e;
$darkGray: #1f2323;
$white: #ffffff;
$whiteAlt: #f3f9f9;
$uiOrange: #f16522;
$uiLightOrange: #ff8042;
$uiYellow: #faae31;
$uiLightYellow: #ffc846;
$uiRobinBlue: #f3f9f9;
$uiBlue: #0c8bb4;
$uiDarkBlue: #0a40eb;
$uiGreen: #139347;
$uiGreenAlt: #9bcd73;
$uiRed: #ff0101;
