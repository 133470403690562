@import "./_colors.module.scss";

.body {
  // margin-top: 55px;
  background-color: $backgroundColorGray;
  min-height: 100vh;

  .content {
    margin-top: 55px;
    background-color: $backgroundColorGray;
  }
}

.mainDrawerOpen {
  margin-left: 0px !important;
  background-color: green;
  width: 100%;
}